.about-title {
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

.about-sub-heading {
  background-image: url('../../imgs/AboutImg.jpg');
  background-size: cover;
  background-position: 50% 25%;
  color: #fff;
  h3{
    font-size: 1.8rem;
    font-weight: 700;
  }
  text-align: center;
  @media screen and (min-width: 200px) {
    padding: 26px 0;
    text-shadow: 0 0 6px #000;
  }
  @media screen and (min-width: 576px) {
    padding: 150px 0;
    text-shadow: 0px 0px 6px #000;
  }
}

.about-container {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  height: 100%;
  text-align: center;
}

.about-final {
  text-align: justify;
}

// ------ below flipped from Home -------

.about-flexy {
  @media screen and (min-width: 200px) {
    width: 100%;
    display: block;
  }
  @media screen and (min-width: 576px) {
    width: 50%;
    display: flex;
    flex-direction: column;
 }
  text-align: center;
  line-height: 1.7rem;
}

.about-text-container {
  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
  }
}


/*********** Icon Colors **************/

#turnkey-icon {
  color: #d6a825;
}

#atom-icon {
  color: #3c027b;
  transform: rotate(30deg);
}