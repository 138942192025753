.rcm-banner {
  background-image: url('../../imgs/RCMImg.jpg');
  background-size: cover;
  background-position: 50% 20%;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  color: #000;
  text-shadow: 0 0 15px #fff;
}

.rcm-title {
  padding-top: 50px;
  text-align: center;
}

.rcm-ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 20px 50px;
  height: 430px;
  margin: 0 auto;
  @media screen and (max-width: 1199px){
    height: 400px;
  }
  @media screen and (max-width: 991px){
    height: 428px;
  }
  @media screen and (max-width: 767px){
    height: auto;
  }
}

.rcm-li {
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
  padding: 0 30px;
  width: 50%;
  text-align: right;
  vertical-align: middle;
  p {
    margin: auto 0;
    font-size: 30px;
    @media screen and (max-width: 1199px){
      font-size: 23px;
    }
    @media screen and (max-width: 991px){
      font-size: 19px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 17px;
      padding-right: 17px;  
    }
  }
  i {
    margin: auto 0;
  }

  @media screen and (max-width: 991px){
    padding: 0 30px 0 0;
    justify-content: space-between;
    text-align: center;
  }

  @media screen and (max-width: 767px){
    width: 100%;
    padding-top: 20px;
    justify-content: space-around;
    text-align: center;
    border-top: 1px solid #000;
  }
  @media screen and (max-width: 400px){
    justify-content: space-between;
  }
}

.rcm-li:first-of-type {
  @media screen  and (max-width: 767px) {
    border-top: none;
  }
}

.rcm-li:nth-child(even) {
  @media screen and (max-width: 991px){
    text-align: center;
  }

  @media screen and (max-width: 767px){
    text-align: center;
  }
}

/*************** Icons **************/

#handshake-icon {
  color: #020533;
  transform: rotate(5deg);
}

#card-icon {
  color: #000;
}

#check-icon {
  color: #080;
}

#billing-icon {
  color: #ab906b;
}

#claim-icon {
  color: #a20700;
}

#dollar-icon {
  color: #157315;
}

#phone-icon {
  color: midnightblue;
  transform: rotate(-30deg);
}