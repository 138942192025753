* {
  font-family: 'Montserrat', sans-serif;
}

.sub-heading {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 45px 0;
}

.main-content {
  padding-bottom: 20px;
}

p {
  font-size: 1.2rem;
  @media screen and (min-width: 200px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (min-width: 576px) {
  padding: 10px;
  }
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

ul {
  padding: 0;
}