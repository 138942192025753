.contact-content-container {
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;
  }
}

.contact-title {
  text-align: center;
  padding: 50px 0;
}

.contact-text-container {
  margin: auto 0;
  @media screen and (max-width: 991px) {
    padding-right: 20px;
  }
}

.open-map {
  width: 425px;
  height: 350px;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 300px;
  }
}

.j {
  background-image: linear-gradient(to bottom, #a4c1f7, rgba(0, 0, 88, 0));
}