.radiation-banner {
  background-image: url('../../imgs/IMRTImg.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  color: #000;
  @media screen and (min-width: 200px) {
    padding: 26px 0;
    text-shadow: 0 0 30px #fff;
  }
  @media screen and (min-width: 576px) {
    padding: 80px 0;
    text-shadow: 0 0 6px #fff;
  }
}

.radiation-content {
  line-height: 2rem;
  @media screen and (min-width: 200px) {
    text-align: center;
  }
  @media screen and (min-width: 576px) {
    text-align: justify;
  }
}

.rad-content-titles {
  text-align: center;
  font-weight: bold;
}

.rad-main-title {
  padding-top: 50px;
  @media screen and (min-width: 200px) {
    font-size: 1.8rem;
  }
  @media screen and (min-width: 576px) {
    font-size: 2.5rem;
  }
}

.imrt-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}

.imrt-list li{
  display: inline-grid;
  list-style-type: none;
  font-size: inherit;
  margin: 0;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.288);
  @media screen and (min-width: 200px) {
    text-align: center;
    width: 100%;
  }
  @media screen and (min-width: 576px) {
    vertical-align: middle;
    text-align: center;
    width: 30%;
  }
}

.imrt-list li span {
  margin: auto 0;
}

.imrt-list .last-list-rad {
  @media screen and (min-width: 200px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.288);
  }
  @media screen and (min-width: 576px) {
    border-bottom: none;
  }

}
.last-list-rad:last-of-type {
  @media screen and (min-width: 200px) {
    border-bottom: none;
  }
}