.home-title {
  background-image: url("../../imgs/HomeImg.jpg");
  background-size: cover;
  background-position: 50% 83%;
  text-align: center;
  @media screen and (min-width:1200px){
    padding: 300px 0;
  }
  padding: 200px 0;
  text-shadow: 0 0 50px #fff;
  width: 100%;
}

.home-sub-heading {
  padding-top: 0px;
}

.home-h4 {
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 10px #b7b7b7;
  padding: 5px 20px;
  border-radius: 20px;
  width: 70%;
}

// ------- flipped from about page -----------

.home-services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 25px;
  a {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 777px;
    margin: 0 auto;
  } 
}

.home-services-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 30px;
  border-radius: 15px;
  box-shadow: 0 0 4px #a0a0a0;
  width: 287.688px;
  margin-bottom: 0;
  transition: box-shadow 0.3s ease-out;
}

.home-services-detail:hover {
  box-shadow: 1px -1px 44px #a0a0a0;
}

.home-icon {
  padding-bottom: 10px;
}

/***************** Icon Colors ******************/

#imrt-icon {
  color: rgb(141, 16, 16);
}

#brachy-icon {
  color: #382a71;
  transform: rotate(45deg);
}

#rcm-icon {
  color: #0d6f01;
}

#management-icon {
  color: #7d656f;
}