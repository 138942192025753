.brachytherapy-page {
  text-align: justify;
}

.brachy-title {
  padding-top: 50px;
  text-align: center;
}

.brachy-content-container {
  margin-top: 25px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  margin-bottom: 35px;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brachy-banner {
  background-image: url('../../imgs/BrachyImg.jpg');
  background-size: cover;
  background-position: 50% 56%;
  display: flex;
  justify-content: center;
  color: #fff;
  @media screen and (min-width: 200px) {
    padding: 26px 0;
    text-shadow: 0 0 4px #000;
  }
  @media screen and (min-width: 576px) {
    padding: 80px 0;
    text-shadow: 0 0 -1px #000;
  }
}

.brachy-text {
  font-size: 1.1rem;
  color: #646466;
}

.brachy-sub-title {
  text-align: center;
}

.brachy-service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
}

.brachy-service-list li {
  border-radius: 23px;
  box-shadow: 0 0 8px #0000005e;
  list-style-type: none;
  font-size: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  cursor: default;
}

.brachy-servics-list-title {
  font-size: 1rem;
  font-weight: 700;
}