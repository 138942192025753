.nav-logo {
  height: 40px;
  width: auto;
}


// Necessary to control nav link colors when clicked.
.dropdown-item:active {
  background-color: inherit;
}

.dropdown-item:focus {
  color: none;
  background-color: none;
  border-color: none;
  outline: none;
  box-shadow: none;
}

.brand-name {
  font-family: 'Cinzel', serif;
  font-size: 29px;
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}