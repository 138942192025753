.asc-header {
  background-image: url('../../imgs/ASCImg.jpg');
  background-size: cover;
  background-position: 50% 60%;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  color: #fff;
  text-shadow: 0 0 7px #000;
}

.asc-title {
  text-align: center;
  padding-top: 50px;
}

.asc-content-container {
  @media screen and (max-width: 991px){
    display: inherit;
  }
  display: flex;
  flex-wrap: wrap;
}

.asc-content-text {
  @media screen and (max-width: 991px){
    width: 100%
  }
  width: 70%;
}

.asc-content-list {
  @media screen and (max-width: 991px){
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 300px;
  }
  @media screen and (max-width: 767px){
    height: 100%;
  }
  width: 30%;
  list-style-type: none;
}

.asc-content-list li {
  @media screen and (max-width: 991px){
    margin: 12px;
  }
  @media screen and (max-width: 767px){
    margin: 12px auto;
  }
  margin: 12px 0;
  padding: 0 0 0 25px;
  background:url(../../imgs/check-circle-regular.svg) no-repeat 0 50%;
  background-size: 18px;
}